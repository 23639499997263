.App-header img {
  max-width: 500px; /* or any size you prefer */
  /* additional styling as needed */
}

/* App.css */

/* ... your existing styles ... */

/* Styles for the table */
table {
  width: 100%;
  border-collapse: collapse; /* Removes the space between table borders */
  margin-top: 20px; /* Adds some space above the table */
}

th, td {
  text-align: center; /* Centers text in the cells */
  padding: 12px; /* Adds padding inside the cells for a better look */
}

th {
  font-size: 18px; /* Larger font size for headings */
  color: #EF476F; /* Color for the heading text to match the button color */
}

td {
  font-size: 16px; /* Font size for the content cells */
}

/* Removes all the borders from the table, th, and td elements */
table, th, td {
  border: none;
}

/* Adds a background to the th element to differentiate it from the td elements */
th {
  background-color: #f3f3f3; /* A light grey background for the table headers */
}

/* Adds a hover effect on the rows */
tr:hover {
  background-color: #f9f9f9; /* A lighter grey background on hover */
}

/* Additional styles to make the table fit in with the AwesomeButton aesthetics */
tbody tr:nth-child(odd) {
  background-color: #fcfcfc; /* Zebra striping for rows, if desired */
}

/* Ensure the input and button styles are aligned with the table aesthetic */
input[type="text"] {
  border: 2px solid #EF476F; /* Border color to match the button */
  border-radius: 30px; /* Rounded borders */
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px; /* Space between input and button */
}

button.right {
  background-color: #EF476F; /* Button background to match the AwesomeButton */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 30px; /* Rounded borders */
  padding: 10px 30px;
  cursor: pointer; /* Pointer cursor on hover */
}

button.right:hover {
  background-color: #FF6392; /* Darker shade on hover */
}

/* Aligns the header logo and input */
.App-header {
  text-align: center; /* Center aligns header contents */
}


.aws-btn {
  --button-default-height: 48px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 6px;
  --button-horizontal-padding: 20px;
  --button-raise-level: 5px;
  --button-hover-pressure: 2;
  --transform-speed: .185s;
  --button-primary-color: #00b8c4;
  --button-primary-color-dark: #d0b400;
  --button-primary-color-light: #ffe11d;
  --button-primary-color-hover: #00a0ab;
  --button-primary-border: 1px solid #ffe11d;
  --button-secondary-color: #ee3253;
  --button-secondary-color-dark: #d0b400;
  --button-secondary-color-light: #ffe11d;
  --button-secondary-color-hover: #ec1a3f;
  --button-secondary-border: 1px solid #ffe11d;
  --button-anchor-color: #293b83;
  --button-anchor-color-dark: #772250;
  --button-anchor-color-light: #b33377;
  --button-anchor-color-hover: #293b83;
  --button-anchor-border: 1px solid #9f2d6a;
  }
  
body {
  font-family: -apple-system, Segoe UI, Ubuntu, Helvetica, Arial;
  background-color: #ffffff;
  color: #353739;
  margin: 0;
  overflow-y: scroll;
}

.App {
  position: relative;
  width: 70%;
  margin: 2em auto;
}

.App-header {
  text-align: center;
}

input {
  font-size: 1.1em;
  height: 2.8em;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  padding-left: .75em;
  width: 80%;
  outline: none;
}

button.right {
  color: white;
  background-color: #0092e5;
  border: none;
  border-radius: 0 5px 5px 0;
  height: 3.03em;
  font-size: 1.1em;
  font-weight: 600;
  outline: none;
  margin-bottom: 20px;
}

button {
  color: white;
  background-color: #0092e5;
  border: none;
  border-radius: 5px 5px 5px 5px;
  height: 3.03em;
  font-size: 1.1em;
  font-weight: 600;
  outline: none;
}

button.left {
  color: white;
  background-color: #0092e5;
  border: none;
  border-radius: 5px 0 0 5px;
  height: 3.03em;
  font-size: 1.1em;
  font-weight: 600;
  outline: none;
}

button:hover {
  background-color: #2779bd;
}

/* Additional styles for progress bar, table, and other elements */

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 8px;
  text-align: center;
  
}

th {
  background-color: #f2f2f2;
}

.awesome-button[disabled] {
  opacity: 0.5; /* Makes the button appear faded when disabled */
  cursor: not-allowed; /* Changes the cursor to indicate the button is not clickable */
}